<template>
  <b-card-code>
    <div v-if="$can('create','Payment Option')" class="custom-search d-flex">
        <b-button variant="outline-primary" @click="showModal">
          {{ $t('Add New') }}
        </b-button>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx
          :data="getData"
          :columns="columns10"
          :filename="'filename'"
          :sheetname="'subjects'"
      >
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData"
                       :csv-title="'My_CSV'"
      >
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="pdfGenerate()">
        <b>PDF</b>
      </button>
    </div>
    <!-- table -->
    <vue-good-table
        :columns="columns"
        :rows="student_fee_options"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <!-- Column: Status -->
        <span v-if="props.column.field === 'name'">
          {{ props.row.name }}
        </span>
        <!-- Column: Status -->
        <span v-if="props.column.field === 'is_active'">
          <b-form-checkbox v-if="$can('status change','Payment Option')" :name="'check-button'+props.row.originalIndex" :id="'check-button'+props.row.originalIndex" @change="statusChange(props.row.id)" v-model="props.row.is_active" :value="1" switch>
            </b-form-checkbox>
            <b-badge v-else :variant="statusVariant(props.row.is_active)">
              {{ props.row.is_active?'Active':'Deactivated' }}
            </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <template >
              <template v-if="props.row.student_fee_types_count <=0">
                  <!-- modal login button -->
                <b-button v-if="$can('update','Payment Option')" @click="selectedRow(props.row)"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.modal-login
                    variant="primary" >
                  <feather-icon icon="Edit2Icon" class="mr-50"/>
                </b-button>
                <b-button v-if="$can('remove','Payment Option')" variant="danger" @click="remove(props.row.id)">
                  <feather-icon icon="TrashIcon" class="mr-50"/>
                </b-button>
              </template>
            </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['5','10','15']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- modal login-->
    <b-modal ref="my-modal"
        hide-footer
        :title="model_mode==='add'?'Add Payment option':'Modify Payment option'"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                >
                  <b-form-input
                      v-model="name"
                      name="name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Payment option name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit button -->
            <b-col>
              <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,BBadge, BPagination, BFormGroup, BFormInput, BFormSelect,
  BModal, BForm,BRow, BCol,BFormCheckbox,
} from 'bootstrap-vue'
import {required} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";
Vue.use(VueExcelXlsx);
export default {
  name:'StudentFeeOption',
  components: {
    BCardCode,
    VueGoodTable,
    BBadge,BFormCheckbox,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BModal,VueJsonToCsv,
    BForm,BRow, BCol,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      required,
      pageLength: 5,
      dir: false,
      model_mode:'add',
      selected_row:{},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Status',
          field: 'is_active',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      status: [{
        1: 'active',
        2: 'deactivate',
      },
        {
          1: 'light-primary',
          2: 'light-danger',
        }],
      columns10: [
        {
          label: 'Name',
          field: 'Name',
        },
        {
          label: 'Status',
          field: 'Status',
        },
      ],
    }
  },
  methods:{
    selectedRow(row){
      this.selected_row=row;
      this.name=row.name;
      this.model_mode='edit';
      this.$refs['my-modal'].show()
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData();
          data.append('name',this.name);
          if (this.model_mode ==='add'){
            apiCall.post('/student/fee/option/store',data).then((response)=>{
              this.hideModal();
              this.$store.dispatch('GET_ALL_STUDENT_FEE_OPTION');
              this.$toaster.success(response.data.message);
            }).catch((error)=>{
              if (error.response.status == 422) this.$toaster.error(error.response.data.errors.name);
              else this.$toaster.error(error.response.data.message);
            });
          }else {
            apiCall.post(`/student/fee/option/update${this.selected_row.id}`,data).then((response)=>{
              this.hideModal();
              if (response.data.status ==='success'){
                this.$toaster.success(response.data.message);
                this.$store.dispatch('GET_ALL_STUDENT_FEE_OPTION');
              }else {
                this.$toaster.error(response.data.message);
              }
            }).catch((error)=>{
              if (error.response.status == 422) this.$toaster.error(error.response.data.errors.name);
              else this.$toaster.error(error.response.data.message);
            });
          }
        }
      })
    },
    statusChange(id){
      apiCall.put(`/student/fee/option/status/change${id}`).then((response)=>{
        this.$swal({
          icon: 'success',
          title: 'Success!',
          text: response.data.message,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        this.$store.dispatch('GET_ALL_STUDENT_FEE_OPTION');
      }).catch((error)=>{
        this.$toaster.error(error.response.data.message);
        this.$store.dispatch('GET_ALL_STUDENT_FEE_OPTION');
      });
    },
    remove(id){
        this.$swal({
          title: 'Are you sure?',
          text: "You want to delete this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            apiCall.delete(`/student/fee/option/remove${id}`).then((response)=>{
              if (response.data.status ==='success'){
                this.$toaster.success(response.data.message);
                this.$store.dispatch('GET_ALL_STUDENT_FEE_OPTION');
              }else {
                this.$toaster.error(response.data.message);
              }
            }).catch((error)=>{
              this.$toaster.error(error.response.data.message);
            });
          }else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Your date is safe :)',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
    showModal() {
      this.$refs['my-modal'].show()
      this.selected_row={};
      this.name='';
      this.model_mode='add';
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    pdfGenerate(){
      let title="Payment options";
      let headers = ['Name','Status'];
      let data =new FormData();
      data.append('title',title);
      data.append('columns',JSON.stringify(headers));
      data.append('data',JSON.stringify(this.getData));
      apiCall.post('/get/common/table/export/pdf',data,{responseType: 'blob'}).then((response)=>{
        const content = response.headers['content-type'];
        download(response.data,'payment_option.pdf', content)
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['student_fee_options']),
    getData(){
      return this.student_fee_options.map(item => ({
        Name:item.name,
        Status: item.is_active?'Active':'Inactive',
        }));
    }
  },
  created() {
    this.$store.dispatch('GET_ALL_STUDENT_FEE_OPTION');
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
